import DocAdmin from '@/store/data'

export default {
  namespaced: true,
  state: () => ({
    reviews: [],
    review: null,
    totalReviews: 0
  }),
  getters: {
    getReviewsByDocumentId: (state) => (documentId) => {
      return state.reviews.filter((review) => review.documentid === documentId)
    },
    getAllReviews: (state) => {
      return state.reviews
    }
  },
  actions: {
    fetchReviews({ commit }, params) {
      return DocAdmin.feedbackReviews.list({ params: params }).then((response) => {
        commit('SET_REVIEWS', response.data.results)
        commit('SET_TOTAL_REVIEWS', response.data.count)

        return Promise.resolve(response.data)
      }).catch((error) => {
        console.error('Error fetching reviews:', error)

        return Promise.reject(error)
      })
    },
    fetchReview({ commit }, reviewId) {
      return DocAdmin.feedbackReviews.get(reviewId).then((response) => {
        commit('SET_REVIEW', response.data)

        return Promise.resolve(response.data)
      }).catch((error) => {
        console.error('Error fetching review:', error)

        return Promise.reject(error)
      })
    }
  },
  mutations: {
    SET_REVIEWS(state, reviews) {
      state.reviews = reviews
    },
    SET_TOTAL_REVIEWS(state, total) {
      state.totalReviews = total
    },
    SET_REVIEW(state, review) {
      state.review = review
    }
  }
}
